@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;600;800&display=swap');

* {
    transition: background-color 0.2s ease-in;
}

h1, h2, h3, h4, h5, h6, p {
    font-family: 'Encode Sans';
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
}

.fw-600 {
    font-weight: 600;
}

.fw-800 {
    font-weight: 800;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: grey;
    border-radius: 50%;
}
.carousel-item, .carousel-item img {
    max-height: 70vh;
    object-fit: cover !important;
}

#home-jb {
    background-image: url("Images/jb4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.justifytext {
    text-align: justify;
    text-justify: inter-word;
}